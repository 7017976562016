// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect, useCallback, useState } from 'react'
import useDidMount from 'beautiful-react-hooks/useDidMount'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import MediaState from '../media-state'

import InitalMetadata from '../schema/inital-metadata'
import UpdateTitle from '../schema/update-title'
import OrganisationSchema from '../schema/organisation-schema'
import WebsiteSchema from '../schema/website-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Styles
import '../../styles/index.less'

// import Header from '../header'
// import '../header/style.less'

// import Footer from '../footer'
// import '../footer/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// /** on mount */
// componentDidUpdate() {
//   if (typeof window !== 'undefined') {
//     if (this.state.client === true) {
//       const element = document.getElementById('layout')
//       element.scrollTop = 0
//     }
//   }
// }

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const Layout = (props) => {
  const [isClient, setIsClient] = useState(false)

  useDidMount(() => {
    setIsClient(true)
  })

  const scrollTop = useCallback(
    debounce(() => {
      const element = document.getElementById('layout')
      element.scrollTop = 0
    }, 300),
    []
  )

  useEffect(() => {
    if (isClient === true) {
      scrollTop()
    }
  }, [isClient])

  const {
    children,
    uri = '',
    lightOrDarkMode,
    className = 'standard-page',
  } = props

  const day = `d${new Date().getDay()}`

  return (
    <MediaState
      className={classnames(
        'layout',
        lightOrDarkMode,
        day,
        className
      )}
      id="layout"
      data-uri={uri}
      client={isClient}
    >
      <InitalMetadata />
      <UpdateTitle />
      <OrganisationSchema />
      <WebsiteSchema />
      {/*<Header />*/}
      <main role="main">{children}</main>
      {/*<Footer />*/}
    </MediaState>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
  })
  // (dispatch) => ({

  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
