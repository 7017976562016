// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect } from 'react'
import classnames from 'classnames'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import min from 'lodash/min'
import max from 'lodash/max'

import { scaleLinear } from 'd3-scale'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import { updateMediaState, updateScreenSizeState } from '../../state/actions'

import getNeighbours from '../../methods/get-neighbours'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const steps = [
  200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600,
]

const range = [15, 25]

const scale = scaleLinear()
  .domain([min(steps), max(steps)])
  .range([min(range), max(range)])
  .clamp(true)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const MediaState = ({
  children,
  className: givenClassName = '',
  client,
  screenSizeState = undefined,
  updateScreenSizeState = undefined,
  mediaState = undefined,
  updateMediaState = undefined,
  ...otherProps
}) => {
  const fx = useMediaQuery

  // Defaults
  let screenWidth = 1440
  let screenHeight = 900
  let screenOrientation = 'landscape'

  // Widths
  const widthIsExtraExtraLarge = fx({ minWidth: 1600 })
  const widthIsExtraLarge = fx({ minWidth: 1201, maxWidth: 1599 })
  const widthIsLarge = fx({ minWidth: 993, maxWidth: 1200 })
  const widthIsMedium = fx({ minWidth: 769, maxWidth: 992 })
  const widthIsSmall = fx({ minWidth: 577, maxWidth: 768 })
  const widthIsExtraSmall = fx({ maxWidth: 576 })

  // Heights
  const heightIsExtraExtraLarge = fx({ minHeight: 1600 })
  const heightIsExtraLarge = fx({ minHeight: 1201, maxHeight: 1599 })
  const heightIsLarge = fx({ minHeight: 993, maxHeight: 1200 })
  const heightIsMedium = fx({ minHeight: 769, maxHeight: 992 })
  const heightIsSmall = fx({ minHeight: 577, maxHeight: 768 })
  const heightIsExtraSmall = fx({ maxHeight: 576 })

  // Orientation
  const isPortrait = fx({ orientation: 'portrait' })
  const isLandscape = fx({ orientation: 'landscape' })
  screenOrientation = isPortrait === true ? 'portrait' : 'landscape'

  // Screen type
  const isRetina = fx({ minResolution: '2dppx' })

  // Get point
  const neighbours = getNeighbours(steps, screenWidth)
  const smallerNeighbour = max(neighbours)
  const point = scale(smallerNeighbour)

  // Classname
  const className = classnames(
    {
      'is-media-aware': true,
      'w-xxl': widthIsExtraExtraLarge,
      'w-xl': widthIsExtraLarge,
      'w-lg': widthIsLarge,
      'w-md': widthIsMedium,
      'w-sm': widthIsSmall,
      'w-xs': widthIsExtraSmall,
      'h-xxl': heightIsExtraExtraLarge,
      'h-xl': heightIsExtraLarge,
      'h-lg': heightIsLarge,
      'h-md': heightIsMedium,
      'h-sm': heightIsSmall,
      'h-xs': heightIsExtraSmall,
      'is-portrait': isPortrait,
      'is-landscape': isLandscape,
      'is-retina': isRetina,
      'is-client': client,
    },
    givenClassName
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ;({ innerWidth: screenWidth, innerHeight: screenHeight } = window)
    }

    // Get point
    const newNeighbours = getNeighbours(steps, screenWidth)
    const newSmallerNeighbour = max(newNeighbours)
    const newPoint = scale(newSmallerNeighbour)

    const newMediaState = {
      widthIsExtraExtraLarge,
      widthIsExtraLarge,
      widthIsLarge,
      widthIsMedium,
      widthIsSmall,
      widthIsExtraSmall,
      heightIsExtraExtraLarge,
      heightIsExtraLarge,
      heightIsLarge,
      heightIsMedium,
      heightIsSmall,
      heightIsExtraSmall,
      isPortrait,
      isLandscape,
      isRetina,
      currentOrientation: isPortrait === true ? 'portrait' : 'landscape',
      currentWidth:
        widthIsExtraExtraLarge === true
          ? 'w-xxl'
          : widthIsExtraLarge === true
          ? 'w-xl'
          : widthIsLarge === true
          ? 'w-lg'
          : widthIsMedium === true
          ? 'w-md'
          : widthIsSmall === true
          ? 'w-sm'
          : 'w-xs',
      currentHeight:
        heightIsExtraExtraLarge === true
          ? 'h-xxl'
          : heightIsExtraLarge === true
          ? 'h-xl'
          : heightIsLarge === true
          ? 'h-lg'
          : heightIsMedium === true
          ? 'h-md'
          : heightIsSmall === true
          ? 'h-sm'
          : 'h-xs',
      screenWidth,
      screenHeight,
      point: newPoint,
    }
    updateMediaState({ ...mediaState, ...newMediaState })
  }, [
    widthIsExtraExtraLarge,
    widthIsExtraLarge,
    widthIsLarge,
    widthIsMedium,
    widthIsSmall,
    widthIsExtraSmall,
    heightIsExtraExtraLarge,
    heightIsExtraLarge,
    heightIsLarge,
    heightIsMedium,
    heightIsSmall,
    heightIsExtraSmall,
    isPortrait,
    isLandscape,
    isRetina,
    screenWidth,
    screenHeight,
    point,
  ])

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedMediaState = compose(
  withState // Add state
)(MediaState)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedMediaState
